import { useEffect } from 'react'

import errorHandler from './error-handler'

export const ZendeskAPI = (...args) => {
  if (typeof window === 'undefined') {
    console.error('Attempting to use client-side Zendesk API on the server')
    return
  } else if (!window.zE) {
    console.error('Failed to load necessary 3rd-party script: Zendesk chat widget')
    errorHandler(new Error('Failed to load necessary 3rd-party script: Zendesk chat widget'))
    return
  }
  window.zE(...args)
}

type PropTypes = {
  email: string
  displayName: string
  mobilePhone: string
  isLoggedIn: boolean
  isMobile: boolean
  isWalledOff: boolean
}

export default function ZendeskChat(props: PropTypes) {
  useEffect(() => {
    // only show the widget on desktop for logged-in users
    if (props.isLoggedIn && !props.isMobile && !props.isWalledOff) {
      ZendeskAPI('webWidget', 'show')
      if (props.email) {
        ZendeskAPI('webWidget', 'identify', {
          name: props.displayName,
          email: props.email,
          phone: props.mobilePhone
        })
      }
    } else {
      ZendeskAPI('webWidget', 'hide')
    }

    return () => {
      ZendeskAPI('webWidget', 'hide')
    }
  }, [props.isLoggedIn, props.isMobile])

  return null
}
